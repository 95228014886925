.reportsCampaignManagement .flexColumnCommon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reportsCampaignManagement .campaignManagementRefreshButton {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: #3e7bfa;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  cursor: pointer;
  border: none;
  height: 32px;
  gap: 6px;
}

.reportsCampaignManagement .campaignManagementLastRefreshText {
  color: #646464;
  font-size: 11px;
  margin: 0px;
  padding: 0px;
  margin-top: 3px;
}

.reportsCampaignManagement .ellipsisText {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.newTableUpperBar {
  padding: 5px 9px 17px 0px;
  /* min-height: 4rem; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  box-shadow: 0px 0px 1px rgb(40 41 61 / 4%), 0px 2px 4px rgb(96 97 112 / 16%);
  border-radius: 10px;
  border: 1px solid #e4e4eb;
}
.reportsCampaignManagement .campainTooltip {
  max-width: 400px;
}
